import React, { useState, useEffect } from "react";
import { Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import axios from "axios";
import { BASE_URL } from "../requestMethod";
import styles from "./uploadFile.module.css";
const { Dragger } = Upload;

const UploadFile = ({ setFileUploaded }) => {
  const [fileList, setFileList] = useState([]);
  const [containerWidth, setContainerWidth] = useState("52%");
  const [preview, setPreview] = useState("");

  const updateContainerWidth = () => {
    if (window.innerWidth <= 768) {
      setContainerWidth("90%");
    } else {
      setContainerWidth("52%");
    }
  };

  useEffect(() => {
    updateContainerWidth();
    window.addEventListener("resize", updateContainerWidth);
    return () => window.removeEventListener("resize", updateContainerWidth);
  }, []);

  const customRequest = async ({ file, onSuccess, onError }) => {
    setPreview(URL.createObjectURL(file));
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(`${BASE_URL}/users/upload`, formData, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_CLIENT_TOKEN}`, // Replace with your actual token retrieval method
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        onSuccess(response.data);
        const url = response.data.data.url;
        console.log("url: ", response.data);
        setFileUploaded([url]); // Adjust if managing multiple files
        message.success(`${file.name} file uploaded successfully.`);
        setPreview(URL.createObjectURL(file));
      } else {
        throw new Error("Not able to upload file");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      onError(error);
      message.error(`${file.name} file upload failed.`);
    }
  };

  const handleRemove = (e) => {
    e.preventDefault();
    setPreview("");
  };

  return (
    <>
      <div>
        {preview ? (
          <div className={styles.preview}>
            {" "}
            <img height={"auto"} width={"100%"} src={preview} alt="img" />
            <button onClick={handleRemove} className={styles.deleteIcon}>
              <img src="Images/delete.svg" alt="" />
            </button>
          </div>
        ) : (
          <Dragger
            name="file"
            fileList={fileList}
            customRequest={customRequest}
            onChange={(info) => {
              const { status } = info.file;
              if (status !== "uploading") {
                setFileList([info.file]); // Manage only the latest file
              }
            }}
            onRemove={() => {
              setFileList([]);
            }}
            style={{
              width: containerWidth,
              margin: "auto",
            }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined style={{ fontSize: "24px", color: "#40a9ff" }} />
            </p>
            <p className="ant-upload-text">Drag file here or click to upload</p>
            <p className="ant-upload-hint">
              Support for a single upload. Strictly prohibit from uploading
              company data or other band files
            </p>
          </Dragger>
        )}
      </div>
    </>
  );
};

export default UploadFile;
