import axios from "axios";

const url = "https://netme.onrender.com";
// Adding a request interceptor

//export const BASE_URL = "https://becomepartner.netme.eu/netmeapi/api/v1"; //PRODUCTION
export const BASE_URL = process.env.REACT_APP_API_URL; // DEVELOPMENT;

export const publicRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Basic ",
  },
});
export const userRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Basic TkVUTUU6TkVUTUVRV0VSVFlVSU9Q",
  },
});

export const sendEmail = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${process.env.REACT_APP_CLIENT_TOKEN}`,
  },
});
