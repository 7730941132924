import React, { useRef } from "react";
import styles from "./addDetails.module.css";
import { Formik, Form, Field } from "formik";
import { message } from "antd";
import CardComponent from "./CardComponent";
import { sendEmail, userRequest } from "../../requestMethod";
import ReCAPTCHA from "react-google-recaptcha";
function LegalRepresentative({
  setLocation,
  setLegalRepDetails,
  legalRepDetails,
  companyDetails,
  selectedAds,
  addDetails,
}) {
  const recaptcha = useRef(null);
  const handleSubmit = async (values) => {
    console.log("va", values);
    setLegalRepDetails(values);
    const captchaValue = recaptcha.current.getValue();
    console.log("captchaValue", captchaValue);
    if (!captchaValue) {
      alert("Please verify the reCAPTCHA!");
    } else {
      // make form submission
      // setAddDetails(values);
      const data = {
        title: addDetails.title,
        body: addDetails.body,
        legalRepresentative: {
          fullName: legalRepDetails.fullName,
          // lastName: legalRepDetails.lastName,
          email: legalRepDetails.email,
          mobile: legalRepDetails.mobile,
        },
        companyName: companyDetails.companyName,
        companyTaxNumber: legalRepDetails.companyTaxNumber,
        address: companyDetails.address,
        businessEmail: legalRepDetails.email,
        businessMobile: legalRepDetails.mobile, //companyDetails.businessMobile,
        category: companyDetails.category,
        // location: values.location,
        city: companyDetails.city,
        targerGroupFrom: addDetails.targerGroupFrom,
        targetGroupTo: addDetails.targetGroupTo,
        releaseDate: addDetails.releaseDate,
        adType: addDetails.adType,
      };
      if (!values.fullName) {
        message.error("Please enter full name");
      }
      //  else if (!values.lastName) {
      //   message.error("Please enter last name");
      // }
      //  else if (!/^[A-Za-z]+$/.test(values.lastName)) {
      //   message.error(
      //     "LastName name should contain only alphabetic characters"
      //   );
      // }
      else if (!values.companyTaxNumber) {
        message.error("Please enter company tax no.");
      } else if (!values.email) {
        message.error("Please enter email");
      } else if (!values.mobile) {
        message.error("Please enter mobile no.");
      } else if (!/^\d{7,15}$/.test(values.mobile)) {
        message.error("Phone number should have between 7 and 15 digits");
      } else {
        console.log("data", data);

        const res = await userRequest.post("/verify", captchaValue);
        console.log("res.data", res.data);
        if (res.data.success) {
          await userRequest
            .post("ads", data)
            .then(() => {
              message.success("Registered successfully");
              setLocation("thanking");
            })
            .catch((err) => {
              const errorMessage =
                err.response?.data?.message || "An error occurred";
              message.error(errorMessage);
            });
          await sendEmail
            .post("/users/email", data)
            .then(() => {
              message.success("Registered successfully");
              setLocation("thanking");
            })
            .catch((err) => {
              const errorMessage =
                err.response?.data?.message || "An error occurred";
              message.error(errorMessage);
            });
        } else {
          message.error("reCAPTCHA validation failed!");
        }
      }
    }
  };

  const handleBack = () => {
    setLocation("adDetails");
  };

  return (
    <div className={styles.container}>
      <div className={styles.form_pic_con}>
        {/* <div className={styles.form_pic}>
          <p id={styles.fpt1}>You want to place ads with us?</p>
          <p id={styles.fpt3}>
            Expand your horizons for your business and place ads in the NETME
            App!
          </p>
        </div> */}
        <img src="Images/adFormOne.svg" alt="" />
      </div>

      <div className={styles.yourBenefits}>
        <p>Your benefits!</p>
      </div>
      <CardComponent />

      <div className={styles.main_form}>
        <div className={styles.sub_form}>
          <Formik
            initialValues={{
              mobile: "",
              email: "",
              firstName: "",
              lastName: "",
              ...legalRepDetails,
            }}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className={styles.headingMargin}>
                <div className={styles.headingLeft}>
                  <p className={styles.l_r}>Legal Information</p>
                  <img src="Images/underline.svg" alt="" />
                </div>
                <div className={styles.headingRight}>
                  <img src="Images/threeByThree.svg" alt="" />
                </div>
              </div>

              <div className={styles.legal}>
                <div className={styles.legal_a}>
                  <label className={styles.label}>
                    Full name<sup>*</sup>
                  </label>
                  <br></br>
                  <Field
                    name="fullName"
                    type="text"
                    className={styles.form_field}
                    placeholder="Enter Full Name"
                  ></Field>
                  <br></br>
                  <label className={styles.label}>
                    E-mail<sup>*</sup>
                  </label>
                  <br></br>
                  <Field
                    name="email"
                    type="email"
                    className={styles.form_field}
                    placeholder="Enter email"
                  ></Field>
                  <br></br>
                  <br />
                  <label className={styles.label}>
                    Company tax no<sup>*</sup>
                  </label>
                  <br></br>
                  <Field
                    name="companyTaxNumber"
                    type="text"
                    className={styles.form_field}
                    placeholder="Enter company tax no"
                  ></Field>
                  <br></br>
                </div>

                <div className={styles.legal_b}>
                  {/* <label className={styles.label}>
                    Last name<sup>*</sup>
                  </label>
                  <br></br>
                  <Field
                    name="lastName"
                    type="text"
                    className={styles.form_field}
                    placeholder="Enter Last Name"
                  ></Field> */}
                  {/* <br></br> */}
                  <label className={styles.label}>
                    Mobile number<sup>*</sup>
                  </label>
                  <br></br>
                  <Field
                    name="mobile"
                    type="number"
                    className={styles.form_field}
                    placeholder="Mobile number"
                  ></Field>
                  <br></br>
                  <div className={styles.recaptcha_btn}>
                    <ReCAPTCHA
                      ref={recaptcha}
                      sitekey={process.env.REACT_APP_SITE_KEY}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.form_btn}>
                <button type="submit" onClick={handleBack}>
                  Back
                </button>
                <button type="submit" onClick={() => handleSubmit}>
                  SEND REQUEST
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default LegalRepresentative;
