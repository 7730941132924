import React, { useEffect, useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";

const LocationPicker = ({ setUserAddress, setLatitude, setLongitude }) => {
  const [value, setValue] = useState(null);
  const [fontSize, setFontSize] = useState("0.9rem");
  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 700px)");

    const handleMediaQueryChange = (event) => {
      if (event.matches) {
        setFontSize("16px");
      } else {
        setFontSize("0.9rem");
      }
    };

    // Initial check
    handleMediaQueryChange(mediaQuery);

    // Add event listener
    mediaQuery.addListener(handleMediaQueryChange);

    // Cleanup event listener on unmount
    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);
  useEffect(() => {
    if (value && value.value && value.value.description) {
      console.log("value", value);
      setUserAddress(value.value.description);
      geocodeByAddress(value.value.description)
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          console.log("lat", lat, lng);
          setLatitude(lat);
          setLongitude(lng);
        })
        .catch((error) => {
          console.error("Error getting latitude and longitude", error);
        });
    }
  }, [value, setLatitude, setLongitude]);

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "100%", // Adjust the width as needed

      marginLeft: "0px",
    }),
    input: (provided) => ({
      ...provided,
      // Add your custom input styles here
    }),
    suggestionsList: (provided) => ({
      ...provided,
      // Add your custom suggestions list styles here
      backgroundColor: "white",
      marginTop: "4px",
      position: "absolute",
      zIndex: 9999,
    }),
    suggestion: (provided) => ({
      ...provided,
      // Add your custom suggestion item styles here
      cursor: "pointer",
      padding: "10px",
    }),
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      minHeight: "10px",
      height: "55px",
      borderRadius: "8px",
      padding: "0px 8px",
      border: "none",
      boxShadow: "none",
      fontFamily: "GalanoGrotesque-Medium",
      fontSize: fontSize,
      backgroundColor: "#f7fcff",
      fontWeight: "500",
      backgroundImage: "url(../../../public/Images/dropdown.svg)",
    }),
  };
  return (
    <div>
      <GooglePlacesAutocomplete
        apiKey={process.env.REACT_APP_MAP_KEY}
        selectProps={{
          value,
          onChange: setValue,
          styles: customStyles,
          placeholder: "Search Address",
        }}
        debounce={600}
        withSessionToken={true}
        minLengthAutocomplete={3}
      />
    </div>
  );
};

export default LocationPicker;

// import { message } from "antd";
// import React, { useEffect, useState, useRef } from "react";
// import GooglePlacesAutocomplete from "react-google-places-autocomplete";
// import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";

// const LocationPicker = ({ setUserAddress, setLatitude, setLongitude }) => {
//   const [value, setValue] = useState(null);
//   const [inputValue, setInputValue] = useState(""); // Track input value manually
//   const [fontSize, setFontSize] = useState("0.9rem");

//   // Ref to focus the input field
//   const inputRef = useRef(null);

//   useEffect(() => {
//     const mediaQuery = window.matchMedia("(max-width: 700px)");

//     const handleMediaQueryChange = (event) => {
//       if (event.matches) {
//         setFontSize("16px");
//       } else {
//         setFontSize("0.9rem");
//       }
//     };

//     handleMediaQueryChange(mediaQuery);

//     mediaQuery.addListener(handleMediaQueryChange);

//     return () => {
//       mediaQuery.removeListener(handleMediaQueryChange);
//     };
//   }, []);

//   useEffect(() => {
//     if (value && value.value && value.value.description) {
//       const address = value.value.description;

//       // Check if the address contains a house number
//       const houseNumberRegex = /\d+/;
//       if (!houseNumberRegex.test(address)) {
//         message.error("Please include a house number in the address.");

//         // Focus the input field if no house number
//         if (inputRef.current) {
//           inputRef.current.focus();
//         }
//         return;
//       }

//       console.log("Selected address:", address);
//       setUserAddress(address);

//       geocodeByAddress(address)
//         .then((results) => getLatLng(results[0]))
//         .then(({ lat, lng }) => {
//           console.log("lat", lat, lng);
//           setLatitude(lat);
//           setLongitude(lng);
//         })
//         .catch((error) => {
//           console.error("Error getting latitude and longitude", error);
//         });
//     }
//   }, [value, setLatitude, setLongitude]);

//   const customStyles = {
//     container: (provided) => ({
//       ...provided,
//       width: "100%",
//       marginLeft: "0px",
//     }),
//     input: (provided) => ({
//       ...provided,
//     }),
//     suggestionsList: (provided) => ({
//       ...provided,
//       backgroundColor: "white",
//       marginTop: "4px",
//       position: "absolute",
//       zIndex: 9999,
//     }),
//     suggestion: (provided) => ({
//       ...provided,
//       cursor: "pointer",
//       padding: "10px",
//     }),
//     control: (provided, state) => ({
//       ...provided,
//       width: "100%",
//       minHeight: "10px",
//       height: "55px",
//       borderRadius: "8px",
//       padding: "0px 8px",
//       border: "none",
//       boxShadow: "none",
//       fontFamily: "GalanoGrotesque-Medium",
//       fontSize: fontSize,
//       backgroundColor: "#f7fcff",
//       fontWeight: "500",
//     }),
//   };

//   return (
//     <div>
//       <GooglePlacesAutocomplete
//         apiKey="AIzaSyC-QiRv067zpiNP9WcRKJ1sLAuZX-0c-mQ" //{process.env.REACT_APP_MAP_KEY}
//         selectProps={{
//           value,
//           onChange: (selectedValue) => {
//             setValue(selectedValue);
//             // Update the inputValue when an address is selected
//             setInputValue(selectedValue?.value?.description || "");

//             // Trigger a forced blur and refocus to update the input box value immediately
//             if (inputRef.current) {
//               inputRef.current.blur();
//               setTimeout(() => {
//                 inputRef.current.focus();
//               }, 0);
//             }
//           },
//           inputValue: inputValue,
//           onInputChange: (newValue, { action }) => {
//             if (action === "input-change") {
//               setInputValue(newValue);
//             }
//           },
//           styles: customStyles,
//           placeholder: "Search Address",
//           inputId: "location-autocomplete", // Setting an ID for the input
//         }}
//         debounce={600}
//         withSessionToken={true}
//       />
//       <input
//         ref={inputRef}
//         type="text"
//         value={inputValue}
//         onChange={(e) => setInputValue(e.target.value)}
//         style={{ display: "none" }} // Hide the input, but keep it for focusing
//       />
//     </div>
//   );
// };

// export default LocationPicker;
